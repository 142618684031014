!((document, $) => {
  'use strict';

  /**
   * Header top menu moves to the main menu for desktop view
   * and revert back the previous position
   * on window resize or first load.
   */
  function topMenuPosition() {
    if ($(window).width() > 990) {
      $('.l-header-inner .block-menu-menu-header-top-menu').prependTo(
        '.l-header-inner .block-system-main-menu'
      );
    }
    else {
      $('.l-header-inner .block-menu-menu-header-top-menu').insertAfter(
        '.l-header-inner .block-system-header'
      );
    }
  }

  // Header top menu move to main menu
  $(document).ready(function() {
    topMenuPosition();

    // Sticky header functionality
    var headerHeight = $('.l-header').outerHeight();
    var layoutMinHeight = $(window).height() - headerHeight;
    $('body').css('padding-top', headerHeight);
    $('.layout').css('min-height', layoutMinHeight);
    $(window).scroll(function() {
      if ($(document).scrollTop() > 1) {
        $('body').addClass('scrolled');
      }
      else {
        $('body').removeClass('scrolled');
      }
    });

    $(window).resize(function() {
      topMenuPosition();
    });

    //add header title on frontpage filter block
    if ($('.front .block-views--exp-proximity-test-page').length) {
      $('<h2 class="header-title">find a provider</h2>').insertBefore(
        $('#views-exposed-form-proximity-test-page')
      );
    }
  });
})(document, jQuery);

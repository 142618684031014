!((document, $) => {
  'use strict';

  $(document).ready(function() {
    $(
      '#edit-field-geofield-distance .form-item-field-geofield-distance-unit select'
    ).prop('disabled', 'disabled');

    $('#edit-field-geofield-distance-distance').attr('step', 25);
    $('#edit-field-geofield-distance-distance').attr('min', 0);

    $(
      '#views-exposed-form-proximity-test-page .geofield-proximity-origin'
    ).attr('placeholder', 'Enter City, State or Zip Code');

    $(
      '#views-exposed-form-proximity-test-block-1 .geofield-proximity-origin'
    ).attr('placeholder', 'Enter City, State or Zip Code');

    //add within before the distance
    $('<span>Within</span>').insertBefore(
      $(
        '#views-exposed-form-proximity-test-block-1 .form-item-field-geofield-distance-distance'
      )
    );

    $('<span>Within</span>').insertBefore(
      $(
        '#views-exposed-form-proximity-test-page .form-item-field-geofield-distance-distance'
      )
    );

    //change the from text to 'of' on provider search
    $(
      '#views-exposed-form-proximity-test-page .geofield-proximity-origin-from'
    ).text('of');

    $(
      '#views-exposed-form-proximity-test-block-1 .geofield-proximity-origin-from'
    ).text('of');

    // Add help text beside full screen icon to the map.
    $('#leaflet-map .leaflet-control-fullscreen-button').append(
      '<span class="use-text">Use</span><span class="bigger">to make map bigger</span><span class="smaller">to make map smaller</span>'
    );

    //limit one checkbox checked at a time on provider search
    // var checkboxes = $(
    //   '#edit-field-in-center-hemodialysis-value-1,\
    //   #edit-field-home-hemodialysis-value-1,\
    //   #edit-field-home-peritoneal-dialysis-value-1,\
    //   #edit-type-1-transplant-centers,\
    //   #edit-field-pediatrics-value-1,\
    //   #edit-field-transplant-locations-value-1'
    // );

    // checkboxes.change(function() {
    //   var currentCheckbox = $(this);

    //   checkboxes
    //     .not(currentCheckbox)
    //     .prop('checked', false)
    //     .removeAttr('disabled');

    //   currentCheckbox.prop('disabled', false);
    // });

    //add dash in phone number
    $(
      '.view-proximity-test .views-row .views-field-field-telephone .field-content'
    ).each(function() {
      var phoneNumber = $(this).text();
      var formattedPhoneNumber = phoneNumber.replace(
        /(\d{3})(\d{3})/,
        '$1-$2-'
      );
      $(this).text(formattedPhoneNumber);
    });
  });
})(document, jQuery);

!((document, $) => {
  'use strict';

  $(document).ready(function() {
    $('.menu > li.has-children > ul').each(function() {
      $(this)
        .children()
        .wrapAll('<div class="menu-inner-wrapper"></div>');
    });

    $('.menu > li.has-children > a').click(function(event) {
      event.preventDefault();
      $(this)
        .parent('li')
        .siblings()
        .removeClass('open');
      $(this)
        .parent('li.has-children')
        .siblings()
        .find('li')
        .removeClass('open');
      $(this)
        .parent('.has-children')
        .addClass('open');
    });

    $('.menu > li.has-children').click(function() {
      $(this)
        .siblings()
        .removeClass('open');
      $(this)
        .siblings()
        .find('li')
        .removeClass('open');
      $(this).addClass('open');
    });

    $('.menu-inner-wrapper li.has-children > a').click(function(event) {
      event.preventDefault();
      $(this)
        .parent('li.has-children')
        .siblings()
        .removeClass('open');
      $(this)
        .parent('li.has-children')
        .siblings()
        .find('li')
        .removeClass('open');
      $(this)
        .parent('li.has-children')
        .addClass('open');
    });

    $(document).on('click', function(event) {
      // If the target is not the main menu or
      // a child of the menu, then process
      // the click event for outside of the Main menu.
      if ($(event.target).closest('.menu').length === 0) {
        $('.menu li').removeClass('open');
      }
    });
  });
})(document, jQuery);

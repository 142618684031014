!((document, $) => {
  'use strict';

  $(document).ready(function() {
    $('.block-search-form .mobile-search-icon').click(function() {
      $(this)
        .parent('.block-content')
        .toggleClass('search-open');
    });

    const path = window.location.pathname;
    if (path.includes('/search/node/') || path.includes('/search/user')) {
      $('.page-title').text('Search');
    }

    $('.block-search-form .form-actions').on('click', function() {
      // Trigger form submission
      $('#search-block-form').submit();
    });
  });
})(document, jQuery);
